import React, { useEffect } from "react"
import Logo from "../assets/images/logo-vertical.svg"
import GooglePlay from "../assets/images/social/google-store.svg"
import AppleStore from "../assets/images/social/apple-store.svg"
import SEO from "../components/seo"
import {trackDownloadWithPixel} from './../services/pixels.service'

const Download = () => {
  const isClient = () => {
    return typeof window === 'object';
  }

  useEffect(() => {
    const mobile = /Android|iPhone|iPad|iPod|Opera Mini/i.test(
      navigator.userAgent
    )
    const ios = /iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent)

    if (isClient() && mobile) {
      if (ios) {
        window.location.href = 'https://apps.apple.com/ph/app/servbees/id1530137634'
      } else {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.servbees'
      }
    }
  }, [])

  return (
    <div className="shared-link">
      <SEO
        pageTitle="Download Servbees"
        title="Bee Part of the Newest Community Commerce App!"
        description="Join Servbees and bee ready to sell, offer services, and find what you need."
      />
      <div className="container">
        <div className="content-wrapper">
          <img src={Logo} alt="Servbees Logo" className="img" />
          <h3 className="title-heading">
            Bee Part of the Newest Community Commerce App!
          </h3>
          <p className="desc">
            Join Servbees and bee ready to sell, offer services, and find what you need within your community.
          </p>
        </div>
        <div className="content-wrapper">
          <div className="desktop">
            <a
              href="https://play.google.com/store/apps/details?id=com.servbees"
              className="btn-google"
              onClick={() => trackDownloadWithPixel('android')}
            >
              <img src={GooglePlay} alt="download on google play store" />
            </a>
            <a
              href="https://apps.apple.com/ph/app/servbees/id1530137634"
              className="btn-apple"
              onClick={() => trackDownloadWithPixel('ios')}
            >
              <img src={AppleStore} alt="download on apple store" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Download
